// @flow

import * as React from 'react';

import { Link } from 'react-router-dom';

type Props = {|
  title: string,
  description: string,
  url: string,
|};

const Card = (props: Props) => (
  <div className="card text-center">
    <div className="card-body">
      <Link to={props.url}>
        <h5 className="card-title">{props.title}</h5>
      </Link>
      <p className="card-text">{props.description}</p>
    </div>
  </div>
);

const IndexPage = (): React.Node => {
  return (
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="jumbotron mt-3 text-center">
          <h1 className="mb-4">D&D Tools</h1>
        </div>
        <div className="card-deck">
          <Card title="Feats List" description="Search Feats by name." url="/feats" />
          <Card title="Items List" description="Search Items by name." url="/items" />
          <Card title="Monsters List" description="Search Monsters by name." url="/monsters" />
          <Card title="Spells List" description="Search and filter Spells by name and stats." url="/spells" />
          <Card
            title="Wild Shape Converter"
            description="Search beasts and apply their scores to yours."
            url="/wild-shape"
          />
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
