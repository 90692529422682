// @flow

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter, Route } from 'react-router-dom';

import IndexPage from './IndexPage';
import * as React from 'react';

const FeatsPage = React.lazy(() => import('./pages/feats/FeatsPage'));
const ItemsPage = React.lazy(() => import('./pages/items/ItemsPage'));
const MonstersPage = React.lazy(() => import('./pages/monsters/MonstersPage'));
const SpellsPage = React.lazy(() => import('./pages/spells/SpellsPage'));
const WildShapePage = React.lazy(() => import('./pages/wild-shape/WildShapePage'));

const App = (): React.Node => (
  <React.Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
      <div className="container-fluid">
        <Route path="/" exact component={IndexPage} />
        <Route path="/feats" exact component={FeatsPage} />
        <Route path="/items" exact component={ItemsPage} />
        <Route path="/monsters" exact component={MonstersPage} />
        <Route path="/spells" exact component={SpellsPage} />
        <Route path="/wild-shape" exact component={WildShapePage} />
      </div>
    </BrowserRouter>
  </React.Suspense>
);

export default App;
